<template>
  <!-- Tournaments and Leagues -->
  <b-row>
    <b-col v-for="(item, no) in tours" v-bind:key="no" :xl="6" cols="*">
      <div class="card card-custom bgi-no-repeat gutter-b" style="height: 175px; background-color: #663259; background-position: calc(100% + 0.5rem) 100%; background-size: 100% auto; background-image: url(/metronic/theme/html/demo1/dist/assets/media/svg/patterns/taieri.svg)">
        <!--begin::Body-->
        <div class="card-body d-flex align-items-center">
          <div>
            <h3 class="text-white font-weight-bolder line-height-lg mb-5">{{ item.name }}</h3>
            <router-link :to="link(item)" class="btn btn-success font-weight-bold px-6 py-3">ดูตารางการแข่ง</router-link>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "game_league",
  data () {
    return {
      tours: []
    }
  },
  methods: {
    recvData() {
      this.axios.get(`/games/${this.game}/tournaments`).then(
        ({ data }) => {
          this.tours = data.tours
        }
      )
    },
    link(item) {
      return `/games/${this.game}/tours/${item.no}`
    }
  },
  computed: {
    game() {
      return this.$route.params.game
    }
  },
  mounted() {
    this.recvData()
  },
  watch: {
    game() {
      this.recvData()
    }
  }
}
</script>

<style>

</style>